import './styles.scss';
import bulmaCalendar = require("bulma-calendar");
import bulmaCarousel = require("bulma-carousel");
import { calendarDaysFromShootDays, listSlots } from './service';
// import Swiffy Slider JS
import { swiffyslider } from '../node_modules/swiffy-slider/dist/js/swiffy-slider.esm';


(window as any).swiffyslider = swiffyslider;

window.addEventListener("load", () => {
    (window as any).swiffyslider.init();
});

const slotsEndpoint = "https://api.visualsbyvioly.com/slots";

const defaultOptions = {
	dateFormat: "yyyy-MM-dd",
}

function updateOptions(elements: any[]) {
	const select = document.querySelector('#timeslotOptions') as HTMLSelectElement;
	select.options.length = 0;
    if (elements.length == 0) {
        select.disabled = true;
    }
	elements.forEach(element => select.appendChild(new Option(element, element)));
}

async function loadSlots() {
	const shootDays = await listSlots();
    const calendarConfig = calendarDaysFromShootDays(shootDays);
	
    if (!calendarConfig) {
        const shootDayCalendar = document.getElementById("shootDay") as HTMLInputElement;
        shootDayCalendar.type = "text";
        shootDayCalendar.value = "No shoot days are available, please check again later";
        shootDayCalendar.disabled = true;
        updateOptions([]);
        return;
    }

	const shootDayCalendar = bulmaCalendar.attach('[id="shootDay"]', {
		...defaultOptions,
		minDate: calendarConfig.minDate as unknown as Date,
		maxDate: calendarConfig.maxDate as unknown as Date,
		startDate: calendarConfig.minDate as unknown as Date,
		disabledDates: calendarConfig.disabledDates,
	})[0];

	updateOptions(calendarConfig.availableDates[calendarConfig.minDate]);

	shootDayCalendar.on('select', function(datepicker) {
		const date = datepicker.data.value() as unknown as string;
		const options = calendarConfig.availableDates[date];
		updateOptions(options);
	});
}
loadSlots();


// Initialize all input of type date
var dateOfBirthCalender = bulmaCalendar.attach('[id="dateOfBirth"]', {
	...defaultOptions,
	startDate: new Date(2000, 1, 1),
});
console.log(dateOfBirthCalender);

bulmaCarousel.attach('[id="content-examples"]', {
	slidesToScroll: 1,
	slidesToShow: 1,
	initialSlide: 1,
	infinite: true,
});

const buttons = document.querySelectorAll('.button');

buttons.forEach((button) => {
  button.addEventListener('click', () => {
    const slide = button.closest('.mmateffu-slides');
    const imgSrc = slide.querySelector('img').src;
    const originalImgSrc = imgSrc.replace('.jp2', '_original.jpeg');

    const link = document.createElement('a');
    link.href = originalImgSrc;
    link.download = 'original.jpg';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
});



function getInputValue(id: string) : string {
    const element = document.getElementById(id) as HTMLInputElement;
    return element.value;
}


async function makeBooking(ev) {

	const firstName = getInputValue("firstName");
	const lastName = getInputValue("lastName");
	const email = getInputValue("email");
	const phone = getInputValue("phone");
	const day = getInputValue("shootDay");
	const booking = getInputValue("timeslotOptions");

	const details = {
		firstName,
		lastName,
		email,
		phone,
		day,
		booking,
	}
	console.log("details:", details);
	const response = await fetch(slotsEndpoint, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(details),
	});
	const text = await response.text();
	console.log(text);
}
document.getElementById("btnSubmit").addEventListener('click', makeBooking)