import {ShootDay} from "@backend/types";

const slotsEndpoint = "https://api.visualsbyvioly.com/slots";

export async function listSlots() : Promise<ShootDay[]> {
    const response = await fetch(slotsEndpoint);
	const slots = await response.json() as ShootDay[];
    return slots;
}

interface CalendarDays {
    minDate: string,
    maxDate: string,
    disabledDates: string[],
    availableDates: Map<string, string[]>;
}

export function calendarDaysFromShootDays(shootDays: ShootDay[]) : CalendarDays | undefined {
    if (shootDays.length == 0) {
        return
    }

    const allowedDates = [];
	const availableDates: Map<string, string[]> = new Map();
	shootDays.forEach(element => {
		allowedDates.push(element.date);
		availableDates[element.date] = element.slots;
	});

	const minDate = allowedDates[0];
	const maxDate = allowedDates[allowedDates.length - 1];
	const disabledDates = [];
	const start = new Date(minDate);
	const end = new Date(maxDate);
	let current = start;
	let maxValues = 0;
	while (true) {
		maxValues += 1
		current = new Date(current.getTime() + (24 * 60 * 60 * 1000));
        if (current >= end) {
			break;
		}
		const searchStr = current.toISOString().slice(0, 10);
		if (!allowedDates.includes(searchStr)) {
			disabledDates.push(searchStr)
		}
	}

    return {
        minDate,
        maxDate,
        disabledDates,
        availableDates,
    }
}